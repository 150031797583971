import React from 'react';
import MainHeader from '../components/mainHeader';
import SEO from '../components/seo';
import Navbar from '../components/navbar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import HomeLogo from '../images/undraw_sweet_home_dkhr.svg'
import HackerMind from '../images/hacker_mind.svg';
import MindMap from '../images/mind_map.svg';
import CodeReview from '../images/code_review.svg';
import CloudHosting from '../images/cloud_hosting.svg';
import ProductIteration from '../images/product_iteration.svg';
import ImageHeadingText from '../components/imageHeadingText';
import IHTCard from '../components/ihtCard';
import DefinitionCard from '../components/DefinitionCard';

const about = () => {
    return (
        <div>
            <SEO 
                title="About"
                description="About Tacent"
            />
            <Container style={{padding: '20px'}} maxWidth='lg'>
                <Navbar/>
                <h1>About Tacent</h1>
                <p>Tacent is a Sydney based, boutique, cybersecurity firm, specialising in application security.</p>
                <h2>What we do</h2>
                <p>We do anything application security (AppSec) related, so it's often best to give us a call to discuss what you're after. However, we commonly perform work for our clients such as:</p>
                <ul>
                    <li>Penetration testing</li>
                    <li>Security architecture</li>
                    <li>Code reviews</li>
                    <li>Cloud security reviews and design</li>
                    <li>DevSecOps</li>
                </ul>
                <h2>Our values</h2>
                <h3>Simplicity</h3>
                <p>We aim to make interactions with us as simple as possible. We are not burdened by bureaucratic process. We have simple, repeatable processes that make working with us smooth.</p>
                <h3>Satisfaction</h3>
                <p>We always go above and beyond to ensure our customers are satisfied. We will work closely with you throughout our engagements to provide the best experience possible.</p>
                <h3>Enjoyment</h3>
                <p>For us, enjoyment comes in two forms. Firstly, we aim to present an enjoyable experience to out customers. Secondly, we enjoy what we do as a team, by having fun and adopting a modern work-life balance.</p>
                {/*
                <Grid spacing={6} justify='center' container>
                    <Grid item md={4}>
                        <DefinitionCard why="We aspire to make every interaction with us simple and stress free." definition="The state of being simple, uncomplicated, or uncompounded." wordType="noun" word="Simplicity" />
                    </Grid>
                    <Grid item md={4}>
                        <DefinitionCard definition="Lorem ipsum dolor sit amet, consectetur adipiscing elit." wordType="noun" word="Customer" /> 
                    </Grid>
                     <Grid item md={4}>
                        <DefinitionCard definition="The state of being simple, uncomplicated, or uncompounded." wordType="noun" word="Simplicity" />
                    </Grid>
                </Grid>
                */}

            </Container>
        </div>
    );
};

export default about;
